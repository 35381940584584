import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"

export default () => (
  <Layout>

    <SEO title="Product features of the Getlocal solution"
        description="Getlocal website builder and e-commerce solution is design and built for travel companies"
        pathname="/features/"
    />
        
    <Hero 
         headerText="Product features" 
         subHeaderText="Getlocal website builder and e-commerce solution"
            /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>Feature rich &amp; lightning fast</h2>        
            
            <p>Simple enough to allow anyone to get started and start selling online - yet powerful enough to compete head to head with the big boys in the market.</p>
            
        
        </div>
      </div>
    </section>



    <section className="section column is-10 is-offset-1 content content-pages">

    <hr />

        <h2 className="center title is-size-4">ONLINE BOOKING & Payment Processing</h2>
        <p className="center">Our ecommerce and CMS solution was designed from the ground up for travel companies. <br />Get the power of an OTA for your own website at a fraction of the cost.</p>
        <div className="columns is-multiline">
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">HIGH CONVERSION AND FRICTIONLESS BOOKING PROCESS</h3>
                    <p className=""> The booking process is streamlined and super simple for your customers. After years of tracking and analyzing we provide a frictionless experience that maximizes conversions.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold"> ACCEPT BOOKINGS AND TAKE PAYMENTS ONLINE</h3>
                    <p className="">Share a calendar with live availability and pricing. Allow your customers to reserve their seat using a super simple booking engine and finalize the payment online using a credit card through a SSL secure website using our built in payment gateway or your preferred partner.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">INTEGRATED WITH BÓKUN'S BOOKING SYSTEM</h3>
                    <p className="">Getlocal is tightly integrated with Tripadvisor’s powerful Bókun inventory manager and booking solution. This allows you to utilize Bókun's full feature set and expose product lists, product details, availability and pricing on your website.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">MULTI LINGUAL AND MULTI CURRENCY</h3>
                    <p className="">Let your international clients to choose the language of their choice and see prices in a currency they know. You have full control of everything with our powerful set of tools.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">INTEGRATED PAYMENT GATEWAY</h3>
                    <p className="">Use our built in payment gateway to take payments directly. No set up or monthly cost, just a small 2% fee of every online transaction that uses the gateway.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">CUSTOM PAYMENT GATEWAYS</h3>
                    <p className="">If you already have a payment gateway in place it’s most likely supported out of the box by Bokun. If the preferred payment gateway in your market isn’t supported then we can custom build a connector to any platform allowing you to take payments online.</p>                   
                </section>
            </div>

        </div>

        <hr />        

        <h2 className="center title is-size-4">CUSTOMIZABLE CMS SOLUTION</h2>
        <p className="center">Create a beautiful engaging website - Convert your visitors - Accept payments online.</p>

        <div className="columns is-multiline">
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">HIGHLY CUSTOMIZABLE AND POWERFUL CMS (CONTENT MANAGER)</h3>
                    <p className="">Our solution is highly customizable and contains a powerful CMS that allows you to create simple and advanced ecommerce sites that integrate with powerful inventory and booking systems.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">CUSTOMIZED WITH YOUR OWN BRANDING</h3>
                    <p className="">The design and page structures work well across all browsers and devices. They can be modified to fit any brand and design. You are also welcome to use our tried and tested design templates as they come out of the box.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">ULTRA FAST AND MOBILE OPTIMIZED</h3>
                    <p className="">Our front end is very lightweight and responsive so it works extremely well on mobile devices and tablets. With advanced techniques and smart caching the customers will experience lightning speed, especially on mobile devices. Some of our clients see over 70% of conversions take place on mobile devices.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">CREATE UNLIMITED NUMBER OF PAGES</h3>
                    <p className="">The built in CMS allows you to create an unlimited number of pages based on various templates. The CMS is simple yet very powerful for this with special needs.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">CREATE AN ENGAGING BLOG</h3>
                    <p className="">Share your knowledge and expertise through the blog section. A good blog can be a key to drive traffic and increase exposure for your website and business.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">ROBUST AND SCALABLE HOSTING</h3>
                    <p className="">The solution is hosted in Amazon’s cloud server (AWS) making it ultra reliable and secure. We make sure to compress images before they are stored on the server and serve through a Content Delivery Network (CDN) so customers from around the world don’t experience slow network connections and loading times.</p>                   
                </section>
            </div>
        </div>

        <hr />

        <h2 className="center title is-size-4">INTEGRATED WITH BOOKING ENGINES</h2>
        <p className="center">...</p>

        <div className="columns is-multiline">
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">BOKUN INTEGRATION</h3>
                    <p className="">With the custom integration on Bokun's API we pull product information, pricing, availability, product lists and other metadata directly. This means there is no need to store important information in multiple places. All product information is live and updates as soon as the inventory data changes.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">SHOPPING BASKET WITH A SIMPLE CHECKOUT</h3>
                    <p className="">The custom checkout process we have built is designed to remove friction and allow customers to complete the online payment safely. Our customers see high conversion due to our tried and tested booking process.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">LAST MINUTE / SPECIAL OFFERS</h3>
                    <p className="">Special discounts can be applied on top of a product based on a rule set. Example all departures tomorrow at 10 AM are sold today with a 30% discount. Special offer products are dynamically shown with the discount and current/previous prices for comparison.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">SAVE INTERESTING PRODUCTS FOR LATER</h3>
                    <p className="">Customers can add interesting products to their favourites without signing up, they can share that list with friends through a simple short link.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">EXTRANETS AND SELF SERVICE KIOSK</h3>
                    <p className="">Serving particular customers and giving retail clerks the power to book and take payments via POS or cash, designed for self service kiosks and tablets.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">PRODUCT SEARCH</h3>
                    <p className="">Search for availability in large or small product sets using date, number of passengers, type of activity or any other custom attribute. </p>                   
                </section>
            </div>
        </div>

        <hr />

        <h2 className="center title is-size-4">ONLINE MARKETING</h2>
        <p className="center">...</p>

        <div className="columns is-multiline">
            <div className="column">
                <section className="section">
                    <h3 className="has-text-weight-semibold">SEARCH ENGINE OPTIMISED</h3>
                    <p className="">The CMS is designed for SEO so all page attributes are customizable, we also expose the product data as json-ld helping search engines understand the products better. </p>                   
                </section>
            </div>
            <div className="column">
                <section className="section">
                    <h3 className="has-text-weight-semibold">CONTENT MARKETING</h3>
                    <p className="">The CMS also comes with user management and blogging features allowing you to create insightful and engaging content to drive traffic to your website.</p>                   
                </section>
            </div>
            <div className="column">
                <section className="section">
                    <h3 className="has-text-weight-semibold">ENHANCED ANALYTICS AND CONVERSION TRACKING</h3>
                    <p className="">STight integration with Google analytics enhanced ecommerce and Facebook campaign tracking. The combination of using a data layer and google tag manager allows for integrations to other tracking tools.</p>                   
                </section>
            </div>
            <div className="column">
                <section className="section">
                    <h3 className="has-text-weight-semibold">AFFILIATE TRACKING</h3>
                    <p className="">Affiliates can be defined with a custom affiliate commission. Once the affiliation ID is used we create a cookie that track all sales for that affiliate. Great for Airbnb hosts and others that want to benefit from recommending your products.</p>                   
                </section>
            </div>
        </div>


        <hr />

        <h2 className="center title is-size-4">UPCOMING FEATURES</h2>
        <p className="center">...</p>

        <div className="columns is-multiline">
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">CROSS SELLING AND UP SELLING DURING CHECKOUT</h3>
                    <p className="">During the checkout and post payment customers can be targeted with additional products that could be added to the basket or purchased later.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">FOLLOW UP EMAILS POST PURCHASE</h3>
                    <p className="">Schedule follow up emails for additional sales and customer service.</p>                   
                </section>
            </div>
            <div className="column is-4">
                <section className="section">
                    <h3 className="has-text-weight-semibold">REVIEW COLLECTION</h3>
                    <p className="">Sync and store reviews for your products and services.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">MULTI DAY PACKAGING</h3>
                    <p className="">Create a package that contains multiple products from different suppliers. Can be defined to include a special discount if bought at the same time.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">SELF SERVICE / BOOKING MANAGEMENT</h3>
                    <p className="">Authentication for the customer to manage their bookings, change dates, cancel etc.</p>                   
                </section>
            </div>
        </div>

    </section>
  </Layout>
)
